import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ReactComponent as MemberLogo } from "../../images/Members.svg";
import { ReactComponent as LTCNetworkLogo } from "../../images/LTC_Network.svg";
import { ReactComponent as ProductPortfolioLogo } from "../../images/Product_Portfolio.svg";
import { ReactComponent as ParticipationLogo } from "../../images/Participation_Status.svg";
import { ReactComponent as RebatesLogo } from "../../images/Rebates.svg";
import { ReactComponent as DiscountLogo } from "../../images/Employee_Discount_Program.svg";
import { ReactComponent as ClinicalLogo } from "../../images/Clinical_Services.svg";
import { ReactComponent as LegislativeLogo } from "../../images/Legislative.svg";
import { ReactComponent as SpecialtyLogo } from "../../images/Specialty_Pharmacy.svg";
import { ReactComponent as ResourcesLogo } from "../../images/Resources_Icon.svg";
import { ReactComponent as BarGraphLogo } from "../../images/Bar Graph.svg";
import { ReactComponent as TruckLogo } from "../../images/Handtruck icon 24px.svg";
import UserContext from "../context/UserContext";
import "./Sidebar.css";
import { Modal } from "react-bootstrap";
import { NetworkContractReport } from "../reports/networkContractModal";
import { RebatePacketReport } from "../reports/rebatePacketModal";
import ORReport from "../reports/ORModal";
import QBRModal from "../reports/QBRModal";
import GrayedButton from "../GrayedButton";
import StatusModal from "../StatusModal";
import useStatusModal from "../../hooks/useStatusModal";
import { pageView } from "../../googleAnalyticsHelper";

//import { SettingsApplicationsTwoTone } from "@material-ui/icons";

export function Sidebar() {
  const [isHovered, setHover] = useState(false);
  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [isSecondary, setIsSecondary] = useState(false);
  const [isLTC, setIsLTC] = useState(false);
  const [isASCD, setIsASCD] = useState(false);
  const [cotList, setCotList] = useState([]);
  const { statusProps, handleErrorStatus, handleAlertStatus } =
    useStatusModal();

  const [modalMessage, setModalMessage] = useState(
    "Please wait while the report is loading..."
  );

  const handleClose = () => {
    setIsReportLoading(false);
    setModalMessage("Please wait while the report is loading...");
  };
  //const localItem = JSON.parse(localStorage.getItem("currentUser"));
  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;
  const roles = currentUser.Info != null ? currentUser.Info.Roles : [];
  const isAcctRep = currentUser.Info && currentUser.Info.IsAcctRep;
  const ltc = ["LTC/NURSING HOME PROVIDER", "OMNICARE"];
  const NAM = currentUser.Location != null ? currentUser.Location.NAM : null;
  const locId = currentUser.Location ? currentUser.Location.LOCID : null;
  const locationType = currentUser.Location ? currentUser.Location.Type : null;
  const locationServices = currentUser.Location
    ? currentUser.Location.Services
    : [];
  const id = currentUser.Location ? currentUser.Location.ID : null;
  const hasOR = currentUser.Location ? currentUser.Location.hasOR : null;

  useEffect(() => {
    if (currentUser.Location != null) {
      setIsSecondary(currentUser.Location.IsSecondaryGPO);

      const arrCot =
        currentUser.Location.Type !== "Member"
          ? currentUser.Location.COTs.map((i) => i.Description)
          : [currentUser.Location.COT];

      setCotList(arrCot);
      setIsLTC(arrCot.some((r) => ltc.includes(r)));
      setIsASCD(arrCot.some((r) => !ltc.includes(r)));
    }
  }, [currentUser.Location]);

  // useEffect(() => {
  //   if (
  //     currentUser.Location &&
  //     (currentUser.Location.Rank || currentUser.Location.Ranks)
  //   ) {
  //     if (cotList.length > 0 && !cotList.some((r) => ltc.includes(r))) {
  //       const status =
  //         locationType === "Member"
  //           ? currentUser.Location.Rank
  //           : currentUser.Location.Ranks.some((r) => r === "S")
  //           ? "S"
  //           : currentUser.Location.Ranks[0];

  //       if (status === "S") {
  //         setSecondaryASCD(true);
  //       } else {
  //         setSecondaryASCD(false);
  //       }
  //     }
  //   }
  // }, [currentUser.Location, cotList, ltc, locationType]);

  const TVPROptions = () => {
    if (isSecondary) {
      return [
        <GrayedButton
          key="AWP Trend Report"
          label="AWP Trend Report"
          title="Contact your account representative"
          classes={["link-button"]}
        />,
        <GrayedButton
          key="Member Business Review"
          label="Member Business Review"
          title="Contact your account representative"
          classes={["link-button"]}
        />,
        // <GrayedButton
        //   key="Therapeutic Category Report"
        //   label="Therapeutic Category Report"
        //   title="Contact your account representative"
        //   classes={["link-button"]}
        // />,
      ];
    } else {
      return [
        <Link
          key="AWP Trend Report"
          to={{ pathname: "/Reports/awp", state: { name: "AWP Trend Report" } }}
        >
          AWP Trend Report
        </Link>,
        <QBRModal
          key="Member Business Review"
          name="Member Business Review"
          title="Member Business Review"
          locType={locationType}
          locId={locId}
          isLTC={isLTC}
          isASCD={isASCD}
          pageView={pageView}
        />,
        currentUser.Location && isLTC && locationType !== "Member" ? (
          <button
            key="Therapeutic Category Report"
            className="link-button"
            onClick={() =>
              TherapeuticCategory(
                setIsReportLoading,
                locId,
                locationType,
                handleErrorStatus,
                handleAlertStatus,
                pageView
              )
            }
          >
            Therapeutic Category Report
          </button>
        ) : null,
      ];
    }
  };

  const rebatesReportsOptions = () => {
    const options = [
      (roles.includes("Profile Viewer") &&
        roles.includes("Trend Value Pricing and Rebates Reports")) ||
      roles.includes("Internal Admin") ||
      isAcctRep ? (
        <Link
          key="Vendor Rebate Reports"
          to={{
            pathname: "/Reports/Rebate",
            state: { name: "Vendor Rebate Reports" },
          }}
        >
          Vendor Rebate Reports
        </Link>
      ) : (
        <GrayedButton
          key="Vendor Rebate Reports"
          title="Current User does not have permissions to access this content"
          classes={["link-button"]}
          label="Vendor Rebate Reports"
          NAM={NAM}
        />
      ),
      (roles.includes("Profile Viewer") &&
        roles.includes("Trend Value Pricing and Rebates Reports")) ||
      roles.includes("Internal Admin") ||
      isAcctRep ? (
        <RebatePacketReport
          name="Rebate Information Packet"
          title="Rebate Information Packet"
          cotList={cotList}
          key="Rebate Information Packet"
          pageView={pageView}
        />
      ) : (
        <GrayedButton
          key="Rebate Information Packet"
          title="Current User does not have permissions to access this content"
          classes={["link-button"]}
          label="Rebate Information Packet"
          NAM={NAM}
        />
      ),
      (roles.includes("Profile Viewer") &&
        roles.includes("Offeror Rebates") &&
        hasOR === true) ||
      ((roles.includes("Internal Admin") || isAcctRep) && hasOR === true) ? (
        <ORReport
          longId={locId}
          id={id}
          type={locationType}
          key="OR Report"
          email={currentUser.Email}
          isAdmin={roles.includes("Internal Admin") || isAcctRep}
        />
      ) : null,
      (roles.includes("Profile Viewer") &&
        roles.includes("Offeror Rebates") &&
        hasOR === true &&
        isLTC &&
        locationType != "Member") ||
      ((roles.includes("Internal Admin") || isAcctRep) &&
        hasOR === true &&
        // isLTC &&
        locationType != "Member") ? (
        <Link
          key="OR Projection Report"
          to={{
            pathname: "/Reports/ORProjection",
            state: { name: "OR Projection Report" },
          }}
        >
          OR Projection Report
        </Link>
      ) : null,
    ];
    return options;
  };

  let links = [
    roles.includes("Profile Viewer") ||
    roles.includes("Internal Admin") ||
    isAcctRep ? (
      <Link
        to={{ pathname: "/member/Locations", state: { name: "Member List" } }}
      >
        <MemberLogo />
        Member List
      </Link>
    ) : (
      <GrayedButton
        title="Current User Does not have access to this feature due to permissions"
        classes={["link-button"]}
        label="Member List"
        logo={<MemberLogo />}
      />
    ),
    locationType === "Member" &&
    (roles.includes("Profile Viewer") ||
      roles.includes("Internal Admin") ||
      isAcctRep) ? (
      <Link
        to={{
          pathname: "/ProductPortfolio",
          state: { name: "Product Portfolio" },
        }}
      >
        <ProductPortfolioLogo /> Product Portfolio
      </Link>
    ) : (
      <GrayedButton
        title="This feature is only available in Location level session"
        classes={["link-button"]}
        label="Product Porfolio"
        logo={<ProductPortfolioLogo />}
      />
    ),
    roles.includes("Profile Viewer") ||
    roles.includes("Internal Admin") ||
    isAcctRep ? (
      <Link
        to={{
          pathname: "/Contracts/Status",
          state: { name: "GPO Contract Status" },
        }}
      >
        <ParticipationLogo />
        GPO Contract Status
      </Link>
    ) : (
      <GrayedButton
        title="Current User Does not have access to this feature due to permissions"
        classes={["link-button"]}
        label="GPO Contract Status"
        logo={<ParticipationLogo />}
      />
    ),

    (roles.includes("Profile Viewer") &&
      roles.includes("Trend Value Pricing and Rebates Reports")) ||
    roles.includes("Internal Admin") ||
    isAcctRep ? (
      <Submenu
        name="Rebate Reports"
        logo={<RebatesLogo />}
        key="Rebate Reports"
        options={rebatesReportsOptions()}
        isHovered={isHovered}
      />
    ) : (
      <GrayedButton
        title="Current User does not have permissions to access this content"
        classes={["link-button"]}
        label="Rebate Reports"
        logo={<RebatesLogo />}
      />
    ),

    (roles.includes("Profile Viewer") &&
      roles.includes("Trend Value Pricing and Rebates Reports")) ||
    roles.includes("Internal Admin") ||
    isAcctRep ? (
      <Submenu
        name="Quarterly & Monthly Reports"
        logo={<BarGraphLogo />}
        key="Quarterly & Monthly Reports"
        options={TVPROptions()}
        isHovered={isHovered}
      />
    ) : (
      <GrayedButton
        title="Your current permissions don't allow you to access this"
        classes={["link-button"]}
        label="Quarterly & Monthly Reports"
        logo={<BarGraphLogo />}
      />
    ),

    cotList.some((r) => ltc.includes(r)) &&
      ((roles.includes("Profile Viewer") && roles.includes("Network Report")) ||
      roles.includes("Internal Admin") ||
      isAcctRep ? (
        <Submenu
          name="Network Reports"
          key="Network Reports"
          logo={<LTCNetworkLogo />}
          options={[
            <NetworkContractReport
              key="Network Contract Value Report"
              name="Network Contract Value Report"
              Title="Network Contract Value Report"
              reportType="NCVR"
              memType={locationType}
              id={locId}
              cot={cotList}
              pageView={pageView}
            />,
            locationType === "Member" ? (
              <button
                key="Contract Assure Report"
                className="link-button"
                onClick={() =>
                  ContractAssure(
                    setIsReportLoading,
                    locId,
                    handleErrorStatus,
                    pageView,
                    setModalMessage
                  )
                }
              >
                Contract Assure Report
              </button>
            ) : (
              <GrayedButton
                title="This feature is only available in Location level session"
                classes={["link-button"]}
                label="Contract Assure Report"
              />
            ),
            <Link key="Member GER Report" to="/reports/ger">
              Member GER Report
            </Link>,
            locationServices.find(
              (s) => s === "LTC Network" || s.Service === "LTC Network"
            ) ? (
              <button
                key="Global GER Report"
                className="link-button"
                onClick={() =>
                  GlobalGER(setIsReportLoading, handleErrorStatus, pageView)
                }
              >
                Global GER Report
              </button>
            ) : (
              []
            ),
            <button
              key="Key Industry Metric Report"
              className="link-button"
              onClick={() =>
                KeyIndustryMetrics(
                  setIsReportLoading,
                  handleErrorStatus,
                  pageView
                )
              }
            >
              Key Industry Metric Report
            </button>,
          ]}
          isHovered={isHovered}
          userData={currentUser}
        />
      ) : (
        <GrayedButton
          title="Your current permissions don't allow you to access this"
          classes={["link-button"]}
          label="Network Reports"
          logo={<LTCNetworkLogo />}
        />
      )),
  ];

  const linkItems = links.map((item, i) => {
    if (item) return <li key={i}>{item}</li>;
  });

  return (
    <React.Fragment>
      <StatusModal statusProps={statusProps} />
      <Modal show={isReportLoading} onHide={handleClose} centered>
        <Modal.Body>
          <p>{modalMessage}</p>
        </Modal.Body>
      </Modal>
      <ul
        className="sidebar-nav"
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
      >
        {linkItems}
        <ContentMenu
          cotList={cotList}
          isSecondary={isSecondary}
          isAcctRep={isAcctRep}
          isLTC={isLTC}
        />
      </ul>
    </React.Fragment>
  );
}

export function Submenu(props) {
  const [isClicked, setClicked] = useState(props.setClick);
  const toggleClick = () => {
    setClicked(!isClicked);
  };
  const subList = props.options;
  const componentClasses = ["sub-menu"];

  if (isClicked && props.isHovered) {
    componentClasses.push("show");
  }
  return (
    <React.Fragment>
      <button className="dropdown-btn" onClick={toggleClick}>
        {props.logo}
        {props.name}
        {isClicked ? (
          <i className="fa fa-angle-up"></i>
        ) : (
          <i className="fa fa-angle-down"></i>
        )}
      </button>
      <div className={componentClasses.join(" ")}>{subList}</div>
    </React.Fragment>
  );
}

function GlobalGER(showModal, handleErrorStatus, pageView) {
  showModal(true);
  axios("/api/Documents/globalGER", {
    method: "GET",
    responseType: "blob", //Force to receive data in a Blob Format
  }).then(
    (response) => {
      //Create a Blob from the PDF Stream
      //Build a URL from the file
      pageView("Global GER");
      showModal(false);
      window.open(URL.createObjectURL(response.data));
    },
    (error) => {
      showModal(false);
      console.error("Error Getting Global GER: ", error.message);
      handleErrorStatus("Error occurred retrieving the Global GER Report");
    }
  );
}

function ContractAssure(
  showModal,
  locId,
  handleErrorStatus,
  pageView,
  setMessage
) {
  showModal(true);
  axios(`/api/Documents/ContractAssure/${locId}`, {
    method: "GET",
    responseType: "blob", //Force to receive data in a Blob Format
  }).then(
    (response) => {
      //Create a Blob from the PDF Stream
      //Build a URL from the file
      console.log("Success", response);
      pageView("Contract Assure");
      showModal(false);
      window.open(URL.createObjectURL(response.data));
    },
    (error) => {
      if (error.response.status === 404) {
        setMessage("No report file  available.");
      } else {
        showModal(false);
        console.error("Error Getting Contract Assure: ", error.message);
        handleErrorStatus(
          "Error occurred retrieving the Contract Assure Report"
        );
      }
    }
  );
}

function KeyIndustryMetrics(showModal, handleErrorStatus, pageView) {
  showModal(true);
  axios("/api/Documents/KeyIndustryMetrics", {
    method: "GET",
    responseType: "blob", //Force to receive data in a Blob Format
  }).then(
    (response) => {
      //Create a Blob from the PDF Stream
      //Build a URL from the file
      pageView("Key Industry Metrics");
      showModal(false);
      window.open(URL.createObjectURL(response.data));
    },
    (error) => {
      showModal(false);
      console.error("Error Getting Key Industry Metrics: ", error.message);
      handleErrorStatus(
        "Error occurred retrieving the Key Industry Metrics Report"
      );
    }
  );
}

function ContentMenu(props) {
  const { cotList, isSecondary, isAcctRep, isLTC } = props;
  const [contentNav, setContentNav] = useState([]);
  const defaultMenu = [
    {
      title: "Business and Medical Products & Services",
      logo: <TruckLogo />,
      codename: "employee_discount_program",
      isSecondary: isSecondary,
      isLTC: isLTC,
    },
    {
      title: "Specialty Pharmacy Solutions",
      logo: <SpecialtyLogo />,
      codename: "specialty_pharmacy_solutions",
      isSecondary: isSecondary,
      isLTC: isLTC,
    },
  ];
  const menuList = [
    {
      title: "Legislative Affairs",
      logo: <LegislativeLogo />,
      codename: "legislative_affairs_advocacy",
      isSecondary: isSecondary,
      isLTC: isLTC,
    },
    {
      title: "Clinical Services",
      logo: <ClinicalLogo />,
      codename: "clinical_services",
      isSecondary: isSecondary,
      isLTC: isLTC,
    },
    // {
    //   title: "Test Page",
    //   logo: <ClinicalLogo />,
    //   codename:"test_page",
    //   isSecondary: isSecondary,
    //   isLTC: isLTC
    // }
  ];
  const ltc = ["LTC/NURSING HOME PROVIDER", "OMNICARE"];
  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;
  const roles = currentUser.Info != null ? currentUser.Info.Roles : [];

  useEffect(() => {
    if (
      roles.includes("Profile Viewer") ||
      roles.includes("Internal Admin") ||
      isAcctRep
    ) {
      if (cotList.length > 0 && contentNav.length === 0) {
        let navList = [];
        if (cotList.some((r) => ltc.includes(r))) {
          if (cotList.includes("OMNICARE")) {
            navList = [
              ...defaultMenu,
              ...menuList.map((r) => {
                return {
                  title: "Long-Term Care " + r.title,
                  logo: r.logo,
                  codename: r.codename,
                  isSecondary: isSecondary,
                  isLTC: isLTC,
                };
              }),
              ...[
                {
                  title: "Long-Term Care Resources",
                  logo: <ResourcesLogo />,
                  codename: "long_term_care_resources",
                  isSecondary: isSecondary,
                  isLTC: isLTC,
                },
              ],
            ];
          }
          //cots with more than just ltc COTs
          else if (cotList.filter((r) => !ltc.includes(r)).length > 0) {
            navList = [
              ...defaultMenu,
              ...menuList,
              ...[
                {
                  title: "Long-Term Care Resources",
                  logo: <ResourcesLogo />,
                  codename: "long_term_care_resources",
                  isSecondary: isSecondary,
                  isLTC: isLTC,
                },
                {
                  title: "LTC Reimbursement Network",
                  logo: <LTCNetworkLogo />,
                  codename: "pharmacy_network",
                  isSecondary: isSecondary,
                  isLTC: isLTC,
                },
              ],
            ];
          } else {
            navList = [
              ...defaultMenu,
              ...[
                {
                  title: "LTC Reimbursement Network",
                  logo: <LTCNetworkLogo />,
                  codename: "pharmacy_network",
                  isSecondary: isSecondary,
                  isLTC: isLTC,
                },
              ],
              ...menuList.map((r) => {
                return {
                  title: "Long-Term Care " + r.title,
                  logo: r.logo,
                  codename: r.codename,
                  isSecondary: isSecondary,
                  isLTC: isLTC,
                };
              }),
              ...[
                {
                  title: "Long-Term Care Resources",
                  logo: <ResourcesLogo />,
                  codename: "long_term_care_resources",
                  isSecondary: isSecondary,
                  isLTC: isLTC,
                },
              ],
            ];
          }
        } else {
          navList = [
            ...defaultMenu,
            ...menuList.map((r) => {
              return {
                title: "Alternate Site " + r.title,
                logo: r.logo,
                codename: r.codename,
                isSecondary: isSecondary,
                isLTC: isLTC,
              };
            }),
            ...[
              {
                title: "Alternate Site Resources",
                logo: <ResourcesLogo />,
                codename: "long_term_care_resources",
                isSecondary: isSecondary,
                isLTC: isLTC,
              },
            ],
          ];
        }
        setContentNav(navList);
      }
    } else {
    }
  }, [contentNav.length, cotList, defaultMenu, ltc, menuList]);

  return (
    <React.Fragment>
      {contentNav.map((r, i) => {
        return (
          <li key={i}>
            <Link
              to={{
                pathname:
                  "/content/${{" +
                  r.codename +
                  "}}/${{" +
                  r.isSecondary +
                  "}}/${{" +
                  r.isLTC +
                  "}}",
                hash: r.codename,
                state: { name: r.title },
              }}
            >
              {r.logo}
              {r.title}
            </Link>
          </li>
        );
      })}
    </React.Fragment>
  );
}

function TherapeuticCategory(
  showModal,
  locId,
  locationType,
  handleErrorStatus,
  handleAlertStatus,
  pageView
) {
  showModal(true);
  axios(
    `/api/Documents/TherapeuticCategory?locId=${locId}&locType=${locationType}`,
    {
      method: "GET",
      responseType: "blob", //Force to receive data in a Blob Format
    }
  ).then(
    (response) => {
      console.log("tcr", response);

      showModal(false);
      pageView("Therapeutic Category");
      if (response.status === 204) {
        handleAlertStatus("No Therapeutic Category Report found.");
      } else {
        //Create a Blob from the PDF Stream
        //Build a URL from the file
        window.open(URL.createObjectURL(response.data));
      }
    },
    (error) => {
      console.error(error.message);
      showModal(false);
      console.log("error.response.status", error.response.status);
      if (error.response.status === 404) {
        handleAlertStatus("No Therapeutic Category Report found.");
      } else {
        handleErrorStatus("An error occurred retrieving the document.");
      }
    }
  );
}
