import React from "react";
import { Button, Col, Form } from "react-bootstrap";

const ORProjectionVendorForm = (props) => {
  function handleSubmit(e) {
    e.preventDefault();

    const form = e.target;
    const data = form.elements;

    console.log("OMGJON", data.vendorCurprev.value);
    props.handleSubmit(data.vendorCurprev.value);
  }

  function handleReset(e) {
    const curprevSelect = document.getElementById("vendorCurprev");
    curprevSelect.selectedIndex = 0;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Col className="col-lg-9 col-12">
          <Form.Row>
            <Form.Group
              as={Col}
              controlId="vendorCurprev"
              className="col-lg-3 col-sm-4"
            >
              <Form.Label>Current or Previous Quarter</Form.Label>
              <Form.Control as="select" required>
                <option value="">Select Current or Previous</option>
                <option value="CURRENT">Current</option>
                <option value="PREVIOUS">Previous</option>
                <option value="PRIOR PREVIOUS">Prior Previous</option>
              </Form.Control>
            </Form.Group>
            <Col
              className="col-lg-1 col-sm-2 col-12 reset-wrap"
              style={{
                marginTop: "2.3%",
              }}
            >
              {/* <label>&nbsp;</label> */}
              <Button onClick={handleReset} className="reset-btn">
                <i className="fa fa-undo"></i> Reset
              </Button>
            </Col>
            <Col
              className="col-lg-2 col-sm-2 col-12"
              style={{
                marginTop: "2.3%",
              }}
            >
              <Form.Label>&nbsp;</Form.Label>
              <Button type="submit" className="results">
                Show Results
              </Button>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
    </Form>
  );
};

export default ORProjectionVendorForm;
